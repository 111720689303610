class CategoryService {
    addUpdateCategory(axios, params, categoryImage) {
        let query = `
                mutation($category_id: ID, $category_title: String!, $category_parent_ids: Int, $enable_add_subcategory: Int, $category_slug: String!, $category_short_description: String, $category_description: String, $category_display_homepage: Int, $category_display_order: Int, $category_image: Upload, $category_type: Int,$category_reward_points:Float,$is_featured: Boolean) {
                    addUpdateProductCategory(
                        category_id: $category_id
                        category_title: $category_title
                        category_parent_ids: $category_parent_ids
                        enable_add_subcategory: $enable_add_subcategory
                        category_slug: $category_slug
                        category_short_description: $category_short_description
                        category_description: $category_description
                        category_display_homepage: $category_display_homepage
                        category_display_order: $category_display_order
                        category_image: $category_image
                        category_type: $category_type,
                        category_reward_points: $category_reward_points
						is_featured: $is_featured
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    category_id: params.category_id,
                    category_title: params.category_title,
                    category_parent_ids: params.category_parent_ids.hasOwnProperty("id") ? parseInt(params.category_parent_ids.id) : 0,
                    enable_add_subcategory: params.enable_add_subcategory,
                    category_slug: params.category_slug,
                    category_short_description: params.category_short_description,
                    category_description: params.category_description,
                    category_display_homepage: params.category_display_homepage,
                    category_image: null,
                    category_type: params.category_type,
                    category_display_order: params.category_display_order,
                    category_reward_points: params.category_reward_points,
					is_featured: params.is_featured
                }
            }
        if(typeof categoryImage === "object") {
            let formData = new FormData()
            formData.append("operations", JSON.stringify(operations))
            const map = {
                "0": ["variables.category_image"]
            }
            formData.append("map", JSON.stringify(map))
            formData.append("0", categoryImage)
            return axios.post('', formData).then(res => {
                return res
            }).catch(err => {
                return Promise.reject(err)
            })
        }else {
            if(typeof categoryImage === "string" && categoryImage !== "") {
                operations.variables.category_image = categoryImage
            }
            return axios.post('', JSON.stringify(operations)).then(res => {
                return res
            }).catch(err => {
                return Promise.reject(err)
            })
        }
    }

    getAllAdminCateogry(axios, params) {
        let body = {
            query: `
                query {
                    mainCategoriesAdmin(
                        paginated: ${params.paginated != undefined ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
                    ) {
                        list {
                            id
                            category_title
                            enable_add_subcategory
                            category_display_homepage
                            category_display_order
                            category_type
                            category_status
                            category_slug
                            category_reward_points
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	getCustomerOrderIds(axios, params) {
        let body = {
            query: `
                query {
                    customerAllOrderIds(customer_id:${params.customer_id})
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    getAllMerchantCateogry(axios, params) {
        let body = {
            query: `
                query {
                    categoriesMerchant(
                        paginated: ${params.paginated != undefined ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
                    ) {
                        list {
                            id
                            category_title
                            category_parent_ids
                            enable_add_subcategory
                            category_display_order
                            category_type
                            category_status
                            category_slug
                            parentcategory
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    getCategoryByID(axios, catID) {
        let body = {
            query: `
                query {
                    categoryDetailsById(
                        category_id: ${catID}
                    ) {
                        id
                        category_title
                        category_parent_ids
                        enable_add_subcategory
                        category_slug
                        category_short_description
                        category_description
                        category_display_homepage
                        category_display_order
                        category_image
                        category_type
                        parentcategory
                        category_reward_points
						is_featured
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	
	generateMainAndFeaturedCatories(axios,params) {
        let body = {
            query: `
                query {
                    generateMainAndFeaturedCatories
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    getMerchantParentCategories(axios, catID) {
        let body = {
            query: `
                query {
                    parentCategoriesMerchant(
                        category_id: ${catID}
                    ) {
                        id
                        category_title
                        subcats
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    updateCategoryStatus(axios, params) {
        let body = {
            query: `
                mutation {
                    changeCategoryStatus(
                        category_id: ${params.id},
                        category_status: ${params.status}
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
}

let categoryService = new CategoryService()
export {
    categoryService
}