import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { FLEET_PREFIX, TOAST_MSG_TYPE, getConstantData } from '../utils/Constants';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import {itemRender} from "../Components/paginationfunction"
import "../assets/css/antdstyle.css"
import { CommonContext } from '../context/CommonContext';
import useAuth from '../hooks/useAuth';
import { useSupportList } from '../hooks/FleetHooks';
import { fleetService } from '../Services/FleetService';
import moment from 'moment';
var tciketStatus=['Open','Closed']

const FleetList = () => {
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo, dashboardPrefix } = useAuth()
    const [params, setParams] = useState({counter: 0, limit: 10, offset: 0, search: "", field: "id", sort: "DESC", paginated: true})
    const {fleetData, loading, error, total} = useSupportList(axiosPrivate, params)

    const render = useMemo(() => {
        const columns = [
            {
                title: 'Ticket#',
                dataIndex: 'ticket_id'
            },
            {
                title: 'Type',
                dataIndex: 'ticket_type'
            },
            {
                title: 'Subject',
                dataIndex: 'ticket_subject'
            },
            {
                title: 'Current Status',
                dataIndex: 'ticket_status'
            },
            {
                title: 'Unread',
                dataIndex: 'total_unread'
            },
            {
                title: 'Customer',
               render: (text,row) => {
                    return `${row.customer_email}`;
                }
            },
            {
                title: 'CreatedAt',
                render: (text,row) => {
                    return moment.unix(row.createdAt/1000).format("YYYY-MM-DD: HH:mm:ss")
                }
            }
        ]
		if(auth.permissions.includes("InActiveCoupon")) {
            columns.push(
                {
                    title: 'Status',
                    render: (text, record) => (
                        <div className="dropdown action-label text-center">
                            <a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">{`${record.ticket_status}`}</a>
                            <div className="dropdown-menu dropdown-menu-right">
                            {
                                tciketStatus.map(status => {
                                    return <a key={`${record.id}_${status}`} className="dropdown-item" href="#" onClick={(e) => changeCityStatus(e, record, status)}>{status}</a>
                                })
                            }
                            </div>
                        </div>
                    )
                        
                }
            )
        }
        if(auth.permissions.includes("EditFleet")) {
            columns.push(
                {
                    title: 'Action',
                    render: (text, record) => (
                        <div className="dropdown dropdown-action text-right">
                            <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                            <div className="dropdown-menu dropdown-menu-right">
                                <Link className="dropdown-item" to={`/support-tickets/comments/${record.ticket_id}`} data-toggle="modal" data-target="#edit_leave"><i className="fa fa-pencil m-r-5" /> Comment</Link>
                            </div>
                        </div>
                    ),
                }
            )
        }
        return columns
    }, [auth.permissions])

    const changeCityStatus = (e, record, objKey) => {
		
        const param = {
            id: record.id,
            status: objKey
        }
		console.log(param)
        const apiPromise = fleetService.changeTicketStatus(axiosPrivate, param)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.changeTicketStatus) {
                showToast("Status Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
				window.location.reload();
            }else {
                showToast("Something Wrong. Please try again later", TOAST_MSG_TYPE.ERROR)
            }
        })
    }

    const searchChanged = (e) => {
        const value = e.target.value
        setParams({...params, offset: 0, search: value})
    }

    const handleTableChange = (pagination, filters, sorter, extra) => {
        if("order" in sorter) {
            let offset = pagination.pageSize * (pagination.current - 1)
            setParams({...params, offset: offset, field: sorter.field, sort: sorter.order === "ascend" ? "ASC" : "DESC"})
            return
        }
        let offset = pagination.pageSize * (pagination.current - 1)
        setParams({...params, offset: offset})
    }

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Support Tickets</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Manage Support Tickets</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active">Tickets</li>
                            </ul>
                        </div>
						<div className="col-sm-6">
                                <Link to={`/support-tickets/create`} className="btn btn-primary btn-width float-right d-block">Add New Ticket</Link>
                        </div>
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
                            <div className='col-md-3'>
                                <input type="text" placeholder='Search' className='float-right form-control table-search' onChange={(e) => searchChanged(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
                                pagination= { {total : total,pageSize: 10, 
                                    showSizeChanger : false,itemRender : itemRender } }
                                style = {{overflowX : 'auto'}}
                                columns={render}
                                loading={loading}               
                                bordered
                                dataSource={fleetData}
                                rowKey={record => record.id}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

export default FleetList;