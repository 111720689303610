import React, {useContext, useEffect, useMemo, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Image, Modal, Table,Select} from 'antd';
import 'antd/dist/antd.css';
import "../assets/css/antdstyle.css";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useOrderDetail, useOrderLog } from '../hooks/OrderHooks';
import { CommonContext } from '../context/CommonContext';
import { ORDER_PREFIX, ORDER_STATUS_IMG, TOAST_MSG_TYPE,CATEGORY_IMG } from '../utils/Constants';
import moment from 'moment';
import { useReactToPrint } from "react-to-print";
import ImageUpload from "../Components/ImageUpload"
import useAuth from '../hooks/useAuth';
import { orderService } from '../Services/OrderService';
import { itemRender } from '../Components/paginationfunction';
import { Collapse } from 'antd';
const { Panel } = Collapse;
const packingStatus={
	0:"Await",
	1: "Packed",
	2: "Out_Of_Stock",
	3: "Refund_Requested",
	4: "Refund_Accepted",
	5: "Refund_Rejected" 	
}
const OrderDetail = () => {
    const navigate = useNavigate()
    const { auth } = useAuth()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    let { orderID } = useParams()
    const [order, setOrder] = useState({order: "", counter: 0})
	const [sendEmail,setSendEmail]= useState(true)
	const [sendNotification,setSendNotification]= useState(true)
    const {loading, orderDetail, orderItems, fleetDetails, error,driver_list} = useOrderDetail(axiosPrivate, order)
    const {loading: logLoading, orderLog, error: logError} = useOrderLog(axiosPrivate, order)
    const [modalState, setModalState] = useState({driver_id:0,status: 0, notes: "", boxCount: null, slot: null, delivery_image: {server: ""},delivery_image1: {server: ""},delivery_image2: {server: ""}})
    useEffect(() => {
        setOrder({...order, order: orderID})
    }, [orderID])
    useEffect(() => {
        if(!loading && !orderDetail) {
            navigate(`${ORDER_PREFIX}`)
        }else if(orderDetail && orderDetail.hasOwnProperty("order_status")){
            setModalState({...modalState, 
			status: orderDetail.order_status, 
			boxCount: orderDetail.box_count, 
			slot: orderDetail.fleet_slot_id,
			driver_id: fleetDetails ? fleetDetails.delivery_driver_id :0,
			delivery_image: {server: orderDetail.delivery_image ? orderDetail.delivery_image : ""},
			delivery_image1: {server: orderDetail.delivery_image1 ? orderDetail.delivery_image1 : ""},
			delivery_image2: {server: orderDetail.delivery_image2 ? orderDetail.delivery_image2 : ""}
			})
        }
    }, [loading])
	console.log(modalState)
    const render = useMemo(() => {
        const columns = [
            {
                title: 'Date/Time',
                dataIndex: 'createdAt',
                render: (t, r) => {
                    return moment.unix(Number(t)/1000).format("MMM DD,YYYY | hh:mmA")
                }
            },
            {
                title: 'Status',
                dataIndex: 'event_new_data',
                render: (t, r) => {
                    return t.order_status_string
                }
            },
            {
                title: 'Comment/Image',
                dataIndex: 'event_new_data',
                render: (t, r) => {
                    if(t.note) {
                        return t.note
                    }else if(t.delivery_image || t.delivery_image1 || t.delivery_image2) {
                        return (<>
                            {
                                t.delivery_image ? 
                                <Image width={100} src={`${ORDER_STATUS_IMG}/${t.delivery_image}`}/> : null
                            }
                            {
                                t.delivery_image1 ? 
                                <Image width={100} src={`${ORDER_STATUS_IMG}/${t.delivery_image1}`}/> : null
                            }
                            {
                                t.delivery_image2 ? 
                                <Image width={100} src={`${ORDER_STATUS_IMG}/${t.delivery_image2}`}/> : null
                            }
                        </>)
                    }
                }
            },
        ]
        return columns
    }, [])

    const onChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        if(name === "status") {
            if(value == 3) {
                setModalState({...modalState, [name]: value, slot: null})
            }else if(value == 8) {
                setModalState({...modalState, [name]: value, boxCount: null})
            }else {
                setModalState({...modalState, [name]: value, slot: null, boxCount: null})
            }
        }else {
            setModalState({...modalState, [name]: value})
        }
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = (e) => {
        e.preventDefault()
        const p = {
            order_id: order.order,
            order_status: Number(modalState.status),
            note: modalState.notes,
            box_count: modalState.boxCount ? Number(modalState.boxCount) : null,
            fleet_slot_id: modalState.slot,
			send_email: sendEmail,
			send_notification: sendNotification,
			driver_id: modalState.driver_id
        }
        if(p.order_status == 3 && !p.box_count) {
            showToast("Please fill box count", TOAST_MSG_TYPE.ERROR)
            return
        }else if(p.order_status == 8 && !p.fleet_slot_id) {
            showToast("Please Fill Fleet Slot", TOAST_MSG_TYPE.ERROR)
            return
        }
		
        if("local" in modalState.delivery_image) {
            delete modalState.delivery_image.local
        }
		if("local" in modalState.delivery_image1) {
            delete modalState.delivery_image1.local
        }
		if("local" in modalState.delivery_image2) {
            delete modalState.delivery_image2.local
        }
        const apiPromise = orderService.updateOrderStatus(axiosPrivate, p, modalState.delivery_image.server, modalState.delivery_image1.server, modalState.delivery_image2.server)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.changeOrderStatus) {
                setModalState((prev) => {
                    return {
                        ...prev,
                        notes: ""
                    }
                })
                let newCounter = order.counter + 1
                setOrder((prev) => {
                    return {
                        ...prev,
                        counter: newCounter
                    }
                })
                setIsModalOpen(false);
                showToast("Order Status Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
            }else {
                showToast("Something Wrong. Please try again later", TOAST_MSG_TYPE.ERROR)
            }
        })
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
	const sendCustomerFeedBack = () => {
		const apiPromise = orderService.sendCustomerFeedBackEmail(axiosPrivate, orderID)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {             
                showToast("FeedBack Email Send Successfully", TOAST_MSG_TYPE.SUCCESS)
        });
	};
    const handlePrint = useReactToPrint({
    content: () => document.querySelector("#order-details"),
    });
	const checkboxChanged = (e) => {
		var name = e.target.name
		if(name==='sendEmail'){		
		setSendEmail(!sendEmail)
		}
		if(name==='sendNotification'){		
		setSendNotification(!sendNotification)
		}
    }
	const driverChanged = (e) => {
       setModalState({...modalState, driver_id: e})
    }
    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Order Detail</title>
            </Helmet>
            <div className="content container-fluid">
            {
                loading ? 
                <p>Loading...</p> :
                <>
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-2 col-12">
                                <h3 className="page-title">Order Detail</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to={`${ORDER_PREFIX}`}>Orders</Link></li>
                                    <li className="breadcrumb-item active">Order Detail</li>
                                </ul>
                            </div>
                            {
                                auth.permissions.includes("ChangeStatusOrder") && 
                                <div className="col-sm-2 col-2">
                                    <a onClick={showModal} className="btn btn-primary text-white btn-width d-block">Change Status</a>
                                </div>
                            }
							{
                                auth.permissions.includes("ChangeStatusOrder") && 
                                <div className="col-sm-2 col-2">
                                    <a href={`${ORDER_PREFIX}/edit/${orderID}`} className="btn btn-danger text-white btn-width  d-block">Edit Order</a>
                                </div>
                            }
							<div className="col-sm-2 col-2">
                                <a href={`${ORDER_PREFIX}/comments/${orderID}`} className="btn btn-success text-white btn-width d-block">Add Comment</a>
                            </div>
							<div className="col-sm-2 col-2">
                                <a onClick={sendCustomerFeedBack} className="btn btn-primary text-white btn-width d-block">Customer FeedBack Email</a>
                            </div>
							<div className="col-sm-2 col-2">
							  <a onClick={handlePrint} className="btn btn-primary text-white btn-width d-block">Print Order Details</a>
                            </div>							
                        </div>
                    </div>
                    <Modal title="Change Status" visible={isModalOpen} onCancel={handleCancel} footer={[
                        <Button type="primary" onClick={(e) => handleOk(e)}>
                            Submit
                        </Button>
                    ]}>
                        <form>
                            <div className='form-group'>
                                <label>Change Status:</label>
                                <select value={modalState.status} onChange={onChange} name='status' className='form-control'>
                                {
                                    (auth.constants && auth.constants.hasOwnProperty("ORDER_STS")) ? 
                                    Object.keys(auth.constants["ORDER_STS"]).map(key => {
                                        return (
                                            <option key={key} value={key}>{auth.constants["ORDER_STS"][key]}</option>
                                        )
                                    }) : null
                                }
                                </select>
                            </div>
                            <div className='form-group'>
                                <label>Notes:</label>
                                <textarea className='form-control' rows={4} value={modalState.notes} name='notes' onChange={onChange}></textarea>
                            </div>
                            {
                                modalState.status == 3 ? 
                                <div className='form-group'>
                                    <label>Box Count:</label>
                                    <input type='text' className='form-control' rows={4} value={modalState.boxCount} name='boxCount' onChange={onChange}/>
                                </div> : null
                            }
							{
                                modalState.status == 8 ? <div className='form-group'>
                                        <label className='mobile-lable-spacing'>Select Driver</label>
                                        <Select 
                                            showSearch={true}
                                            className='select col-md-12'
                                            options={[{id: 0, name: "Select Driver"}, ...driver_list]}
                                            value={modalState.driver_id}
                                            onChange={driverChanged}
                                            optionFilterProp={"name"}
                                            fieldNames={{label: "name", value: "id"}}
                                        />
                                    </div>	: null
                            }
                            {
                                modalState.status == 8 ?
                                <div className='form-group'>
                                    <label>Fleet Slots:</label>
                                    <input type='text' className='form-control'  name='slot' onChange={onChange}/>
                                </div>								: null
                            }	
							{
                                modalState.status > 8 ?
                                <div className='row'>
								<div className="col-sm-4 col-4">
                                    <label>Delivery Image_1:</label>
                                    <ImageUpload
                                    fileName={modalState.delivery_image.server}
                                    fileList={
                                    modalState.delivery_image.server
                                        ? [
                                            {
                                            uid: modalState.delivery_image,
                                            url:"local" in modalState.delivery_image ? `${modalState.delivery_image.local.url}` : `${ORDER_STATUS_IMG}/${modalState.delivery_image.server}`
                                            }
                                        ]
                                        : []
                                    }
                                    getFile={file => {
                                        setModalState({...modalState, delivery_image: file});
                                    }}
                                    onRemove={() => setModalState({...modalState, delivery_image: {server: ""}})}
                                    crud="create"
                                />
								</div>
								<div className="col-sm-4 col-4">
                                    <label>Delivery Image_2:</label>
                                    <ImageUpload
                                    fileName={modalState.delivery_image1.server}
                                    fileList={
                                    modalState.delivery_image1.server
                                        ? [
                                            {
                                            uid: modalState.delivery_image1,
                                            url:"local" in modalState.delivery_image1 ? `${modalState.delivery_image1.local.url}` : `${ORDER_STATUS_IMG}/${modalState.delivery_image1.server}`
                                            }
                                        ]
                                        : []
                                    }
                                    getFile={file => {
                                        setModalState({...modalState, delivery_image1: file});
                                    }}
                                    onRemove={() => setModalState({...modalState, delivery_image1: {server: ""}})}
                                    crud="create"
                                />
								</div>
								<div className="col-sm-4 col-4">
                                    <label>Delivery Image_3:</label>
                                    <ImageUpload
                                    fileName={modalState.delivery_image2.server}
                                    fileList={
                                    modalState.delivery_image2.server
                                        ? [
                                            {
                                            uid: modalState.delivery_image2,
                                            url:"local" in modalState.delivery_image2 ? `${modalState.delivery_image2.local.url}` : `${ORDER_STATUS_IMG}/${modalState.delivery_image2.server}`
                                            }
                                        ]
                                        : []
                                    }
                                    getFile={file => {
                                        setModalState({...modalState, delivery_image2: file});
                                    }}
                                    onRemove={() => setModalState({...modalState, delivery_image2: {server: ""}})}
                                    crud="create"
                                />
								</div>
                                </div> : null
							}
							<div className="form-group">
									<input type="checkbox"  style={{marginRight: "10px"}} checked={sendEmail} onChange={checkboxChanged} name="sendEmail"/>
									<label htmlFor="notification_flag">Send Email To Customer ?</label>
								</div>
						    <div className="form-group">
									<input type="checkbox"  style={{marginRight: "10px"}} checked={sendNotification} onChange={checkboxChanged} name="sendNotification"/>
									<label htmlFor="notification_flag">Send Notification To Customer ?</label>
								</div>
                            
                        </form>
                    </Modal>
					<div id="order-details">
                        <div className='row'>
                            <div className='col-md-6 order-2 order-md-1'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <Collapse>
                                            <Panel header="Order Detail:" key="1">
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label>Order ID</label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='text'>{orderDetail.order_id}</div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label>Order Date/Time</label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='text'>{moment.unix(orderDetail.createdAt/1000).format("YYYY-MM-DD hh:mm")}</div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label>Delivery Slot</label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='text'>{`${orderDetail.shippping_slot.shipping_date} | ${orderDetail.shippping_slot.enter_time}-${orderDetail.shippping_slot.exit_time}`}</div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label>Status</label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='text'>{orderDetail.order_status_string}</div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label>Total Price</label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='text'>{`$${orderDetail.total_paid_amount}`}</div>
                                                    </div>
                                                </div>
												<div className='row'>
                                        <div className='col-4'>
                                            <label>Extra Price to Pay</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>{`$${orderDetail.extra_amount_payment}`}</div>
                                        </div>
                                    </div>
									<div className='row'>
                                        <div className='col-4'>
                                            <label>Refund Amount</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>{`$${orderDetail.refund_amount}`}</div>
                                        </div>
                                    </div>									
                                    <div className='row'>
                                        <div className='col-4'>
                                            <label>Paid Order Total</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>{`$${orderDetail.paid_on_ordertime}`}</div>
                                        </div>
                                    </div>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label>Total Items</label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='text'>{orderDetail.total_items}</div>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse>                                    
                                    </div>
                                </div>
                                <div className='card'>
                                    <div className='card-body'>
                                        <Collapse>
                                            <Panel header="Fleet details & Delivery Details" key="1">
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label>Package Count: </label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='text'>{orderDetail.box_count || ""}</div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label>Fleet Slot: </label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='text'>{orderDetail.fleet_slot_id || ""}</div>
                                                    </div>
                                                </div>
                                                {
                                                    fleetDetails ? 
                                                    <>
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <label>Fleet: </label>
                                                            </div>
                                                            <div className='col-8'>
                                                                <div className='text'>{fleetDetails.fleet_licence_number}</div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <label>Driver: </label>
                                                            </div>
                                                            <div className='col-8'>
                                                                <div className='text'>{fleetDetails.driver_name}</div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <label>Driver Phone: </label>
                                                            </div>
                                                            <div className='col-8'>
                                                                <div className='text'>{fleetDetails.driver_phone}</div>
                                                            </div>
                                                        </div>
                                                    </> : null
                                                }
												{
                                                    orderDetail.delivery_service ==='CanadaPost' ? 
                                                    <>
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <label>Delivery: </label>
                                                            </div>
                                                            <div className='col-8'>
                                                                <div className='text'>{orderDetail.delivery_service}</div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <label>service-code: </label>
                                                            </div>
                                                            <div className='col-8'>
                                                                <div className='text'>{orderDetail.shippping_slot['capost_shippingslot']['service-code']}</div>
                                                            </div>
                                                        </div>
														<div className='row'>
                                                            <div className='col-4'>
                                                                <label>service-name: </label>
                                                            </div>
                                                            <div className='col-8'>
                                                                <div className='text'>{orderDetail.shippping_slot['capost_shippingslot']['service-name']}</div>
                                                            </div>
                                                        </div>
														<div className='row'>
                                                            <div className='col-4'>
                                                                <label>total_weight: </label>
                                                            </div>
                                                            <div className='col-8'>
                                                                <div className='text'>{orderDetail.shippping_slot['capost_shippingslot']['total_weight']}</div>
                                                            </div>
                                                        </div>
														<div className='row'>
                                                            <div className='col-4'>
                                                                <label>total_dimenstion: </label>
                                                            </div>
                                                            <div className='col-8'>
                                                                <div className='text'>{orderDetail.shippping_slot['capost_shippingslot']['total_dimenstion']}</div>
                                                            </div>
                                                        </div>
														<div className='row'>
                                                            <div className='col-4'>
                                                                <label>expected-delivery-date: </label>
                                                            </div>
                                                            <div className='col-8'>
                                                                <div className='text'>{orderDetail.shippping_slot['capost_shippingslot']['service-standard']['expected-delivery-date']}</div>
                                                            </div>
                                                        </div>
														<div className='row'>
                                                            <div className='col-4'>
                                                                <label>price-details(due Amt): </label>
                                                            </div>
                                                            <div className='col-8'>
                                                                <div className='text'>{orderDetail.shippping_slot['capost_shippingslot']['price-details']['due']}</div>
                                                            </div>
                                                        </div>
														<div className='row'>
                                                            <div className='col-4'>
                                                                <label>price-details(base Amt): </label>
                                                            </div>
                                                            <div className='col-8'>
                                                                <div className='text'>{orderDetail.shippping_slot['capost_shippingslot']['price-details']['base']}</div>
                                                            </div>
                                                        </div>
                                                    </> : null
                                                }
                                            </Panel>
                                        </Collapse>
                                    </div>
                                </div>
                                <div className='card'>
                                    <div className='card-body'>
                                        <Collapse>
                                            <Panel header="Transaction details:" key="1">
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label>Transaction ID</label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='text'><a href={`https://dashboard.stripe.com/payments/${orderDetail.payment_id}`} target='_blank'>{orderDetail.payment_id}</a></div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label>Payment Mode</label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='text'>Online</div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label>Transactions Status</label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='text'>Completed</div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label>Transaction Date/Time</label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='text'>{moment.unix(orderDetail.transaction_date_time).format("YYYY-MM-DD hh:mm")}</div>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 order-1 md-order-md-2'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <Collapse defaultActiveKey={['1']}>
                                            <Panel header="Delivery & customer detail:" key="1">
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label>Name</label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='text'>{`${orderDetail.shipping_address.first_name} ${orderDetail.shipping_address.last_name}`}</div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label>Email</label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='text'>{orderDetail.shipping_address.email}</div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label>Phone Number</label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='text'>
                                                        <a href={`tel:${orderDetail.shipping_address.phone_code}${orderDetail.shipping_address.phone}`} target="_blank" rel="noopener noreferrer">{`${orderDetail.shipping_address.phone_code}-${orderDetail.shipping_address.phone}`}</a>  <a href={`https://wa.me/${orderDetail.shipping_address.phone_code}${orderDetail.shipping_address.phone.replace(/[^0-9.]/g, '')}`} target="_blank" rel="noopener noreferrer"><i class="fa fa-whatsapp whatsapp-icon wa-call-item"></i></a></div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label>Address</label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='text'>{`${orderDetail.shipping_address.entry_code ? `Entry Code: (${orderDetail.shipping_address.entry_code}), ` : ""}${orderDetail.shipping_address.apartment ? ` Aprt/Unit: ${orderDetail.shipping_address.apartment}, ` : ""}${orderDetail.shipping_address.formatted_address}`}</div>
                                                    </div>
                                                </div>
                                                {
                                                    orderDetail.shipping_address.suggession ? 
                                                    <div className='row'>
                                                        <div className='col-4'>
                                                            <label>Suggession: </label>
                                                        </div>
                                                        <div className='col-8'>
                                                            <div className='text'>{orderDetail.shipping_address.suggession}</div>
                                                        </div>
                                                    </div> : null
                                                }
                                            </Panel>
                                        </Collapse>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <div className='row'>
                        <div className='col-md-6' id="hideOrderTransaction">
                        </div>
                        <div className='col-md-6'>
                        </div>
                    </div>
                    <div className='gray-bg gray-bar'>
                        <div className='row mx-0'>
						    <div className='col-md-1 col-3'>
                                <div className="heading-bar">Img</div>
                            </div>
                            <div className='col-md-4 col-9'>
                                <div className="heading-bar">Product Name</div>
                            </div>
                            <div className='col-md-1 col-2'>
                                <div className="heading-bar">Rack</div>
                            </div>
                            <div className='col-md-1 col-2'>
                                <div className="heading-bar">QTY</div>
                            </div>
                            <div className='col-md-2 col-4'>
                                <div className="heading-bar">Sale Price</div>
                            </div>
                            <div className='col-md-1 col-4'>
                                <div className="heading-bar">Total</div>
                            </div>
							<div className='col-md-2 col-2'>
                                <div className="heading-bar">Pack Status</div>
                            </div>
                        </div>
                    </div>
                    {
                        orderItems.map((item) => {
                            return (
                                <div className='white-bar' key={item.id}>
                                    <div className='row mx-0'>
									<div className='col-md-1 col-2 mob-mb-10'>
                                            <div className="">
											<Image height={30} src={`${CATEGORY_IMG}/${item.product_details.product_images[0]}`}/></div>
                                        </div>
                                        <div className='col-md-4 col-10 mob-mb-10'>
                                            <div className="line-break pb-1">{`${item.product_details.product_title} (${item.variant_details.variant_value} ${item.variant_details.variant_name} x ${item.variant_details.variant_qty})  (${item.product_details.product_brand})`}</div>
                                        </div>
                                        <div className='border-bottom col-md-1 col-3'>
                                            <div className="heading-bar">{item.product_rackid}</div>
                                        </div>
                                        <div className='border-bottom col-md-1 col-3'>
                                            <div className="heading-bar">{item.quantity}</div>
                                        </div>
                                        <div className='border-bottom col-md-2 col-3'>
                                            <div className="heading-bar">{`$${item.product_price}`}</div>
                                        </div>
                                        <div className='border-bottom col-md-1 col-3'>
                                            <div className="heading-bar">{`$${item.total_price}`}</div>
                                        </div>
										<div className='border-bottom col-md-1 col-2'>
                                            <div className="heading-bar"><span className={packingStatus[item.packing_status]}>{packingStatus[item.packing_status]}
													{
													item.packing_status === 2 ? `(${item.out_stock_qty})` : ''
													} 
													</span></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className='white-bar'>
                        <div className='row mx-0'>
                            <div className='col-md-7'>
                            </div>
                            <div className='col-6 col-md-3'>
                                <div className="heading-bar w-full mb-2">Order Summary</div>
                                <div className="semibold w-full mb-2">Subtotal:</div>
                                <div className="semibold w-full mb-2">Total Discount:</div>
                                <div className="semibold w-full mb-2">Delivery Fees:</div>
								<div className="semibold w-full mb-2">Tax(HST):</div>
                                <div className="semibold w-full mb-2">Coupon Price({orderDetail.coupon_data.coupon_code}):</div>
                                <div className="semibold w-full mb-2">Tip Price:</div>
								<div className="semibold w-full mb-2">Reward Points Used:</div>
                                <div className="heading-bar w-full mb-2">Total Price:</div>
                            </div>
                            <div className='col-6 col-md-2'>
                                <div className="heading-bar w-full mb-2 low-visibility">Order Summary</div>
                                <div className="semibold w-full mb-2">{`$${orderDetail.price_total.toFixed(2)}`}</div>
                                <div className="semibold w-full mb-2">{`$${(orderDetail.price_total - orderDetail.sub_total).toFixed(2)}`}</div>
                                <div className="semibold w-full mb-2">{`$${orderDetail.shipping_charges}`}</div>
								 <div className="semibold w-full mb-2">{`$${orderDetail.products_hst_charge}`}</div>
                                <div className="semibold w-full mb-2">{`$${orderDetail.coupon_discount}`}</div>
                                <div className="semibold w-full mb-2">{`$${orderDetail.driver_tip}`}</div>
								<div className="semibold w-full mb-2">{`$${orderDetail.reward_points_used ? orderDetail.reward_points_used.reward_points_usd : 0 }`} ({`${orderDetail.reward_points_used ? orderDetail.reward_points_used.reward_points : 0 }`})</div>
                                <div className="heading-bar w-full mb-2">{`$${orderDetail.total_paid_amount}`}</div>
                            </div>
                        </div>
                    </div>
                    <div className='white-bar' >
                        <div className='row mx-0' id='logsection'>
                            <div className="table-responsive">
                                <Table
                                    pagination= { {total : orderLog.length,pageSize: 100, 
                                        showSizeChanger : false,itemRender : itemRender } }
                                    style = {{overflowX : 'auto'}}
                                    columns={render}
                                    loading={logLoading}               
                                    bordered
                                    dataSource={orderLog}
                                    rowKey={record => record.id}
                                    // onChange={handleTableChange}
                                />
                            </div>
                        </div>
                    </div>
					</div>
                </>
            }
            </div>
        </div>
    )
}
export default OrderDetail;