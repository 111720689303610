class FleetService {
	addUpdateSupportComment(axios, params, deliveryImage, deliveryImage1, deliveryImage2,deliveryImage3) {
        let query = `
				mutation($ticket_id: String,$ticket_type: String,$ticket_subject: String,$ticket_message: String!,$comment_image1: Upload,$comment_image2: Upload,$comment_image3: Upload,$comment_image4: Upload,
					$customer_id: Int!,
					$ticket_status: String,
					$send_notification: Boolean,
					$email_notification: Boolean
				) {
                    addOrCommentSupportTicketCMS(
                        ticket_id: $ticket_id
                        ticket_type: $ticket_type
                        ticket_subject: $ticket_subject
                        ticket_message: $ticket_message
                        comment_image1: $comment_image1
						comment_image2: $comment_image2
						comment_image3: $comment_image3
						comment_image4: $comment_image4
						customer_id: $customer_id
						ticket_status: $ticket_status
						send_notification: $send_notification
						email_notification: $email_notification
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    ...params,
					comment_image1: null
                }
            }
       if(typeof deliveryImage === "object" || typeof deliveryImage1 === "object" || typeof deliveryImage2 === "object") {
            let formData = new FormData()
            formData.append("operations", JSON.stringify(operations))
            const map = {
                "0": [],
                "1": [],
				"2": []
            }
			
            if(typeof deliveryImage === "object") {
                map["0"].push("variables.comment_image1")
            }else if(typeof deliveryImage === "string" && deliveryImage !== ""){
                operations.variables.comment_image1 = deliveryImage
            }
			if(typeof deliveryImage1 === "object") {
                map["1"].push("variables.comment_image2")
            }else if(typeof deliveryImage1 === "string" && deliveryImage1 !== ""){
                operations.variables.comment_image2 = deliveryImage1
            }
			if(typeof deliveryImage2 === "object") {
                map["2"].push("variables.comment_image3")
            }else if(typeof deliveryImage2 === "string" && deliveryImage2 !== ""){
                operations.variables.comment_image3 = deliveryImage2
            }
			if(typeof deliveryImage3 === "object") {
                map["2"].push("variables.comment_image4")
            }else if(typeof deliveryImage2 === "string" && deliveryImage2 !== ""){
                operations.variables.comment_image4 = deliveryImage2
            }
			
			formData.append("map", JSON.stringify(map))
			
            if(typeof deliveryImage === "object") {
                formData.append("0", deliveryImage)
            }
            if(typeof deliveryImage1 === "object") {
                formData.append("1", deliveryImage1)
            }
			if(typeof deliveryImage2 === "object") {
                formData.append("2", deliveryImage2)
            }
			if(typeof deliveryImage3 === "object") {
                formData.append("3", deliveryImage3)
            }
            
            return axios.post('', formData).then(res => {
                return res
            }).catch(err => {
                return Promise.reject(err)
            })
			
        }else {
            if(typeof deliveryImage === "string" && deliveryImage !== "") {
                operations.variables.delivery_image = deliveryImage
            }
            return axios.post('', JSON.stringify(operations)).then(res => {
                return res
            }).catch(err => {
                return Promise.reject(err)
            })
        }
    }
	orderReportsData(axios, params) {
        let body = {
            query: `
                query {
                    orderReportsData(
                        is_excel: ${params.is_excel !== undefined ? params.is_excel : false}
                        report_name: "${params.report_name}"
						paginated: ${params.paginated !== undefined ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	getproductsInCarts(axios, params) {
        let body = {
            query: `
                query {
                    productsInCarts(
                        paginated: ${params.paginated !== undefined ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                    ) {
                        list {
							product_id
							product_title
							variant_value
							total
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	getSupportListing(axios, params) {
        let body = {
            query: `
                query {
                    supportTicketListing(
                        paginated: ${params.paginated !== undefined ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
                    ) {
                        list {
                            id
                            ticket_id
                            ticket_customer_id
                            ticket_type
                            ticket_subject
                            ticket_status
                            customer_name
							customer_email
                            total_unread
                            createdAt
                            updatedAt
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	supportTicketDetails(axios, params) {
        let body = {
            query: `
                query {
                    supportTicketDetails(
                        ticket_id: "${params.ticket_id}"
                    ) {
                        list {
                            id
                            ticket_id
                            ticket_customer_id
                            ticket_type
                            ticket_subject
                            ticket_status
                            customer_name
							customer_email
                            total_unread
                            createdAt
							ticket_images
							ticket_message
                            updatedAt
							ticket_triggered_by
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    getFleetListing(axios, params) {
        let body = {
            query: `
                query {
                    fleetListingCMS(
                        paginated: ${params.paginated !== undefined ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
                    ) {
                        list {
                            id
                            fleet_make
                            fleet_model
                            fleet_year
                            insurance_cost
                            insurance_expiry
                            notes
                            fleet_other_details
                            fleet_licence_number
                            fleet_order_limit
                            fleet_status
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    updateCustomerDetails(axios, params) {
        let query = `
                mutation($customer_id: Int!, $first_name: String!, $last_name: String, $customer_address_city: String!, $phone: String!, $email: String!, $country_phone_code: String!) {
                    updateCustomerDetails(
                        customer_id: $customer_id
                        first_name: $first_name
                        last_name: $last_name
                        customer_address_city: $customer_address_city
                        phone: $phone
                        email: $email
                        country_phone_code: $country_phone_code
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    ...params
                }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	sendNotificationInCartProducts(axios, params) {
        let query = `
                mutation($product_id: Int!, $title: String!, $message: String!) {
                    sendNotificationsInCartProducts(
                        product_id: $product_id
                        title: $title
                        message: $message
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    ...params
                }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    addUpdateFleet(axios, params) {
        let query = `
                mutation($id: Int, $fleet_make: String!, $fleet_model: String!, $fleet_year: String, $insurance_cost: Float, $insurance_expiry: String, $notes: String, $fleet_licence_number: String!, $fleet_order_limit: Int!, $fleet_other_details: JSON) {
                    createUpdateFleet(
                        id: $id
                        fleet_make: $fleet_make
                        fleet_model: $fleet_model
                        fleet_year: $fleet_year
                        insurance_cost: $insurance_cost
                        insurance_expiry: $insurance_expiry
                        notes: $notes
                        fleet_licence_number: $fleet_licence_number
                        fleet_order_limit: $fleet_order_limit
                        fleet_other_details: $fleet_other_details
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    ...params
                }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	getCustomerByID(axios, customerId) {
        let body = {
            query: `
                query {
                    customerDetailsByid(
                        customer_id: ${customerId}
                    ) {
                        customer_details{
                            id
                            first_name
                            last_name
                            email
                            phone
                            country_phone_code
                            customer_address_city
                        }
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    getFleetByID(axios, fleetID) {
        let body = {
            query: `
                query {
                    fleetDetailsById(
                        fleet_id: ${fleetID}
                    ) {
                        fleet_details{
                            id
                            fleet_make
                            fleet_model
                            fleet_year
                            insurance_cost
                            insurance_expiry
                            notes
                            fleet_other_details
                            fleet_licence_number
                            fleet_order_limit
                        }
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    changeFleetStatus(axios, params) {
        let body = {
            query: `
                mutation {
                    changeFleetStatus(
                        id: ${params.id},
                        fleet_status: ${params.status}
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	changeTicketStatus(axios, params) {
        let body = {
            query: `
                mutation {
                    changeTicketStatus(
                        id: ${params.id},
                        ticket_status: "${params.status}"
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
}
let fleetService = new FleetService()
export {
    fleetService
}