import React, {useContext, useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, DatePicker, Modal,Image } from 'antd';
import 'antd/dist/antd.css';
import "../assets/css/antdstyle.css";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useOrderDetail } from '../hooks/OrderHooks';
import { CommonContext } from '../context/CommonContext';
import { ORDER_PREFIX, TOAST_MSG_TYPE, convertTimeTo12Hours,BANNER_IMG } from '../utils/Constants';
import moment from 'moment';
import { Select } from 'antd';
import useAuth from '../hooks/useAuth';
import { orderService } from '../Services/OrderService';
import { useSearchProductList, useSlotList } from '../hooks/ProductHooks';

const packingStatus = [
    {label: "AWAIT", value: 0},
    {label: "PACKED", value: 1},
	{label: "OUT OF STOCK", value: 2},
	{label: "REFUND REQUEST", value: 3},
	{label: "REFUND ACCEPTED", value: 4},
	{label: "REFUND REJECTED", value: 5},
]
const OrderEdit = () => {
    const navigate = useNavigate()
    const { auth } = useAuth()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    let { orderID } = useParams()
    const [order, setOrder] = useState({order: "", counter: 0})
    const {loading, orderDetail, orderItems, fleetDetails, error,driver_list} = useOrderDetail(axiosPrivate, order)
    const [addProductForm, setAddProductForm] = useState({show: false, product: undefined, quantity: 1})
    const [searchParams, setSearchParams] = useState({orderID: orderID, search: ""})
    const {loading: searchLoader, productData} = useSearchProductList(axiosPrivate, searchParams)
    const [modalState, setModalState] = useState({status: 0, notes: "", boxCount: null, slot: null,driver_id:0})
    const [isModalOpen, setIsModalOpen] = useState(false);
	const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
	const [refundParams, setRefundParams] = useState({refund_title: "", refund_images: [],refund_comment:""})
    const [cartProducts, setCartProducts] = useState([])
	const [customRefundAmount,setCustomRefundAmount]= useState(0)
	const [sendEmail,setSendEmail]= useState(true)
	const [sendEditEmail,setSendEditEmail]= useState(false)
	const [sendNotification,setSendNotification]= useState(true)
    const [slotForm, setSlotForm] = useState({date: undefined, time: undefined, sentObj: undefined})
    const {loading: slotLoading, slot} = useSlotList(axiosPrivate, orderDetail && orderDetail.shipping_address && orderDetail.shipping_address.city ? orderDetail.shipping_address.city : undefined, slotForm.date, )
	
    useEffect(() => {
        setOrder({...order, order: orderID})
    }, [orderID])
	
    useEffect(() => {
        if(!loading && !orderDetail) {
            navigate(`${ORDER_PREFIX}`)
        }else if(orderDetail && orderDetail.hasOwnProperty("order_status")){
            let itemsArray = []
            for(let i=0;i<orderItems.length;i++) {				
                let item = orderItems[i]				
				if (cartProducts.filter(e => e.id === item.id).length === 0) {					
					itemsArray.push({
						...item.variant_details,
						product_title: item.product_details.product_title,
						product_brand: item.product_details.product_brand,
						variant_price: item.product_price,
						variant_id: item.variant_id,
						packing_status: item.packing_status,
						item_other_data: item.item_other_data,
						out_stock_qty: item.out_stock_qty,
						quantity: item.quantity,
						product_rackid: item.product_rackid,
						id: item.id,
						order_item_id: item.id
					})
				}
            }
            setCartProducts((prev) => {
                return [
                    ...prev,
                    ...itemsArray
                ]
            })
            setModalState({...modalState,driver_id: fleetDetails ? fleetDetails.delivery_driver_id :0, status: orderDetail.order_status, boxCount: orderDetail.box_count, slot: orderDetail.fleet_slot_id})
        }
    }, [loading])
	console.log(cartProducts)
	
    const onSearchChange = (value) => {
        setSearchParams({...searchParams, search: value})
    }
    const toggleAddProductForm = () => {
        const showing = addProductForm.show
        if(showing) {
            setAddProductForm({...addProductForm, show: false, product: undefined, quantity: 1})
        }else {
            setAddProductForm({...addProductForm, show: true})
        }
    }
    const addProductChange = (e) => {
        const parsedData = JSON.parse(e)
        setAddProductForm({...addProductForm, product: parsedData})
    }
    const changeNewProductQty = (e) => {
        const numberRegex = /^\d+$/;
        if(e.target.value === "" || numberRegex.test(e.target.value)) {
            setAddProductForm({...addProductForm, quantity: e.target.value})
        }
    }
    const changeExistProductQty = (e, id) => {
        const numberRegex = /^\d+$/;
        if(e.target.value === "" || numberRegex.test(e.target.value)) {
            const pro = cartProducts.find((p) => p.id == id)
            if(pro) {
                pro.quantity = e.target.value
            }
            let newArray = [...cartProducts]
            setCartProducts(newArray)
        }
    }
	const changeExistProductPackingStatus = (value, id) => {
		console.log(value)
        const numberRegex = /^\d+$/;
        if(value === "" || numberRegex.test(value)) {
            const pro = cartProducts.find((p) => p.id == id)
            if(pro) {
                pro.packing_status = value
            }
            let newArray = [...cartProducts]
            setCartProducts(newArray)
        }
    }
    const addNewProductClicked = () => {
        if(!addProductForm.product) {
            showToast("Please Select Product", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!addProductForm.quantity) {
            showToast("Please Add Valid Quantity", TOAST_MSG_TYPE.ERROR)
            return
        }
        let lastItemID = 0
        if(cartProducts.length) {
            lastItemID = cartProducts[cartProducts.length - 1].id
        }
        let newItem = {
            ...addProductForm.product,
            quantity: addProductForm.quantity,
            id: lastItemID + 1,
			order_item_id: 0
        }
        let newProductArray = [...cartProducts]
        newProductArray.push(newItem)
        setCartProducts((prev) => {
            return [
                ...newProductArray
            ]
        })
        setAddProductForm({...addProductForm, product: undefined, quantity: 1})
        showToast("Product Added", TOAST_MSG_TYPE.SUCCESS)
    }
    const deleteProduct = (id) => {
        const remainingProducts = cartProducts.filter((pro) => pro.id !== id)
        setCartProducts((prev) => {
            return [
                ...remainingProducts
            ]
        })
    }
    const slotDateChanged = (date, dateString) => {
        if(dateString) {
            setSlotForm({...slotForm, date: dateString})
        }else {
            setSlotForm({...slotForm, date: undefined, sentObj: undefined})
        }
    }
    const slotTimeSlected = (e, slotObj) => {
		//console.log(slotForm);
		//console.log(slotObj);
        setSlotForm({...slotForm, sentObj: slotObj})
    }
    const onChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        if(name === "status") {
            if(value == 3) {
                setModalState({...modalState, [name]: value, slot: null})
            }else if(value == 8) {
                setModalState({...modalState, [name]: value, boxCount: null})
            }else {
                setModalState({...modalState, [name]: value, slot: null, boxCount: null})
            }
        }else {
            setModalState({...modalState, [name]: value})
        }
    }
    const showModal = () => {
        setIsModalOpen(true);
    };
	const showRefundDetails = (e, refund) => {
		setIsRefundModalOpen(true);
		console.log(refund)
		setRefundParams({refund_comment: refund.refund_comment,refund_title: refund.refund_title,refund_images: refund.refund_images})
	};
	const driverChanged = (e) => {
       setModalState({...modalState, driver_id: e})
    }
    const handleOk = (e) => {
        e.preventDefault()
        const p = {
            order_id: order.order,
            order_status: Number(modalState.status),
            note: modalState.notes,
            box_count: modalState.boxCount ? Number(modalState.boxCount) : null,
            fleet_slot_id: modalState.slot,
			send_email: sendEmail,
			send_notification: sendNotification,
			driver_id: modalState.driver_id
        }
        if(p.order_status == 3 && !p.box_count) {
            showToast("Please fill box count", TOAST_MSG_TYPE.ERROR)
            return
        }else if(p.order_status == 8 && !p.fleet_slot_id) {
            showToast("Please Fill Fleet Slot", TOAST_MSG_TYPE.ERROR)
            return
        }
        const apiPromise = orderService.updateOrderStatus(axiosPrivate, p)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.changeOrderStatus) {
                setModalState((prev) => {
                    return {
                        ...prev,
                        notes: ""
                    }
                })
                let newCounter = order.counter + 1
                setOrder((prev) => {
                    return {
                        ...prev,
                        counter: newCounter
                    }
                })
                setIsModalOpen(false);
                showToast("Order Status Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
            }else {
                showToast("Something Wrong. Please try again later", TOAST_MSG_TYPE.ERROR)
            }
        })
    };
    const handleCancel = () => {
        setIsModalOpen(false);
		setIsRefundModalOpen(false);
    };
	
	const onChangeField = (e) => {
        const name = e.target.name
        const value = e.target.value
       setCustomRefundAmount(value)
    }
    const updateOrder = () => {
        let param = {
			send_email: sendEditEmail,
            order_id: orderID,
            orderItems: [],
            shippping_slot: slotForm.sentObj,
			custom_refund_amount: Number(customRefundAmount)
        }
        for(let i=0;i<cartProducts.length;i++) {
            if(!cartProducts[i].quantity) {
                showToast("Please add quantity for all products", TOAST_MSG_TYPE.ERROR)
                return
            }
            param.orderItems.push({
                qty: Number(cartProducts[i].quantity),
                variant_id: cartProducts[i].variant_id,
				order_item_id: cartProducts[i].order_item_id,
				packing_status: Number(cartProducts[i].packing_status)
            })
        }
		console.log(param)
        const apiPromise = orderService.editCustomerOrder(axiosPrivate, param)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.editCustomerOrder) {
				setCartProducts([])
                showToast("Order Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
                window.location.reload();
            }
        })
    }
	const checkboxChanged = (e) => {
		var name = e.target.name
		if(name==='sendEmail'){		
		setSendEmail(!sendEmail)
		}
		if(name==='sendEditEmail'){		
		setSendEditEmail(!sendEditEmail)
		}
		if(name==='sendNotification'){		
		setSendNotification(!sendNotification)
		}
    }
    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Order Detail</title>
            </Helmet>
            <div className="content container-fluid">
            {
                loading ? 
                <p>Loading...</p> :
                <>
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-6">
                                <h3 className="page-title">Order Detail</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to={`${ORDER_PREFIX}`}>Orders</Link></li>
                                    <li className="breadcrumb-item active">Order Detail</li>
                                </ul>
                            </div>
                            {
                                auth.permissions.includes("ChangeStatusOrder") && 
                                <div className="col-sm-3">
                                    <a onClick={showModal} className="btn btn-primary text-white btn-width float-right d-block">Change Status</a>
                                </div>
                            }
							<div className="col-sm-2 col-2">
                                <a href={`${ORDER_PREFIX}/comments/${orderID}`} className="btn btn-success text-white float-left-small btn-width float-right d-block">Add Comment</a>
                            </div>
                        </div>
                    </div>
                    <Modal title="Change Status" visible={isModalOpen} onCancel={handleCancel} footer={[
                        <Button type="primary" onClick={(e) => handleOk(e)}>
                            Submit
                        </Button>
                    ]}>
                        <form>
                            <div className='form-group'>
                                <label>Change Status:</label>
                                <select value={modalState.status} onChange={onChange} name='status' className='form-control'>
                                {
                                    (auth.constants && auth.constants.hasOwnProperty("ORDER_STS")) ? 
                                    Object.keys(auth.constants["ORDER_STS"]).map(key => {
                                        return (
                                            <option key={key} value={key}>{auth.constants["ORDER_STS"][key]}</option>
                                        )
                                    }) : null
                                }
                                </select>
                            </div>
                            <div className='form-group'>
                                <label>Notes:</label>
                                <textarea className='form-control' rows={4} value={modalState.notes} name='notes' onChange={onChange}></textarea>
                            </div>
                            {
                                modalState.status == 3 ? 
                                <div className='form-group'>
                                    <label>Box Count:</label>
                                    <input type='text' className='form-control' rows={4} value={modalState.boxCount} name='boxCount' onChange={onChange}/>
                                </div> : null
                            }
							{
                                modalState.status == 8 ? <div className='form-group'>
                                        <label className='mobile-lable-spacing'>Select Driver</label>
                                        <Select 
                                            showSearch={true}
                                            className='select col-md-12'
                                            options={[{id: 0, name: "Select Driver"}, ...driver_list]}
                                            value={modalState.driver_id}
                                            onChange={driverChanged}
                                            optionFilterProp={"name"}
                                            fieldNames={{label: "name", value: "id"}}
                                        />
                                    </div>	: null
                            }
                            {
                                modalState.status == 8 ?
                                <div className='form-group'>
                                    <label>Fleet Slots:</label>
                                    <input type='text' className='form-control' rows={4} value={modalState.slot} name='slot' onChange={onChange}/>
                                </div> : null
                            }
                            <div className="form-group">
									<input type="checkbox"  style={{marginRight: "10px"}} checked={sendEmail} onChange={checkboxChanged} name="sendEmail"/>
									<label htmlFor="notification_flag">Send Email To Customer ?</label>
								</div>
						    <div className="form-group">
									<input type="checkbox"  style={{marginRight: "10px"}} checked={sendNotification} onChange={checkboxChanged} name="sendNotification"/>
									<label htmlFor="notification_flag">Send Notification To Customer ?</label>
								</div>
                        </form>
                    </Modal>
					<Modal title="Change Status" visible={isRefundModalOpen} onCancel={handleCancel}>
                        <form>
                            <div className='form-group'>
                                <label>Refund Title : {refundParams.refund_title}</label>                                
                            </div>
							<div className='form-group'>
                                <label>Refund Message : {refundParams.refund_comment}</label>                                
                            </div>
                            <div className='form-group'>
                                <label>Images:</label>
								{
									refundParams.refund_images && refundParams.refund_images.length>0
									? refundParams.refund_images.map((item, index) => (
									<Image width={100} src={`${BANNER_IMG}/${item}`}/>
									)) : ' no images.'
								}
                            </div>
                        </form>
                    </Modal>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h4>Order Detail:</h4>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <label>Order ID</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>{orderDetail.order_id}</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <label>Order Date/Time</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>{moment.unix(orderDetail.createdAt/1000).format("YYYY-MM-DD hh:mm")}</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <label>Delivery Slot</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>{`${orderDetail.shippping_slot.shipping_date} | ${orderDetail.shippping_slot.enter_time}-${orderDetail.shippping_slot.exit_time}`}</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <label>Status</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>{orderDetail.order_status_string}</div>
                                        </div>
                                    </div>
									<div className='row'>
                                        <div className='col-4'>
                                            <label>Total Price</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>{`$${orderDetail.total_paid_amount}`}</div>
                                        </div>
                                    </div>
									<div className='row'>
                                        <div className='col-4'>
                                            <label>Extra Price to Pay</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>{`$${orderDetail.extra_amount_payment}`}</div>
                                        </div>
                                    </div>
									<div className='row'>
                                        <div className='col-4'>
                                            <label>Refund Amount</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>{`$${orderDetail.refund_amount}`}</div>
                                        </div>
                                    </div>									
                                    <div className='row'>
                                        <div className='col-4'>
                                            <label>Paid Order Total</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>{`$${orderDetail.paid_on_ordertime}`}</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <label>Total Items</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>{orderDetail.total_items}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h4>Delivery & customer detail:</h4>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <label>Name</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>{`${orderDetail.shipping_address.first_name} ${orderDetail.shipping_address.last_name}`}</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <label>Email</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>{orderDetail.shipping_address.email}</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <label>Phone Number</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>{`${orderDetail.shipping_address.phone_code}-${orderDetail.shipping_address.phone}`}</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <label>Address</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>{`${orderDetail.shipping_address.entry_code ? `Entry Code: (${orderDetail.shipping_address.entry_code}), ` : ""}${orderDetail.shipping_address.apartment ? ` Aprt/Unit: ${orderDetail.shipping_address.apartment}, ` : ""}${orderDetail.shipping_address.formatted_address}`}</div>
                                        </div>
                                    </div>
                                    {
                                        orderDetail.shipping_address.suggession ? 
                                        <div className='row'>
                                            <div className='col-4'>
                                                <label>Suggession: </label>
                                            </div>
                                            <div className='col-8'>
                                                <div className='text'>{orderDetail.shipping_address.suggession}</div>
                                            </div>
                                        </div> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h4>Transaction details:</h4>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <label>Transaction ID</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'><a href={`https://dashboard.stripe.com/payments/${orderDetail.payment_id}`} target='_blank'>{orderDetail.payment_id}</a></div>
                                        </div>
                                    </div>
									<div className='row'>
                                        <div className='col-4'>
                                            <label>Transaction Amount</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>{orderDetail.paid_on_ordertime}</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <label>Payment Mode</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>Online</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <label>Transactions Status</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>Completed</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <label>Transaction Date/Time</label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>{moment.unix(orderDetail.transaction_date_time).format("YYYY-MM-DD hh:mm")}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h4>Fleet details:</h4>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <label>Package Count: </label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>{orderDetail.box_count || ""}</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <label>Fleet Slot: </label>
                                        </div>
                                        <div className='col-8'>
                                            <div className='text'>{orderDetail.fleet_slot_id || ""}</div>
                                        </div>
                                    </div>
                                    {
                                        fleetDetails ? 
                                        <>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <label>Fleet: </label>
                                                </div>
                                                <div className='col-8'>
                                                    <div className='text'>{fleetDetails.fleet_licence_number}</div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <label>Driver: </label>
                                                </div>
                                                <div className='col-8'>
                                                    <div className='text'>{fleetDetails.driver_name}</div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <label>Driver Phone: </label>
                                                </div>
                                                <div className='col-8'>
                                                    <div className='text'>{fleetDetails.driver_phone}</div>
                                                </div>
                                            </div>
                                        </> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='white-bar mb-4'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <button onClick={toggleAddProductForm} className='btn btn-primary text-white btn-width float-right d-block'>Add Product</button>
                            </div>
                        </div>
                        {
                            addProductForm.show ? 
                            <div className='row'>
                                <div className='col-md-3 col-12'>
                                    <label>Product Name:</label>
                                    <Select
                                        value={addProductForm.product ? JSON.stringify(addProductForm.product) : undefined}
                                        filterOption={false}
                                        showSearch={true}
                                        className='select form-control-2 col-md-12'
                                        notFoundContent={null}
                                        options={productData.map((p) => ({
                                            label: `${p.product_title} ${p.variant_value}${p.variant_name} x ${p.variant_qty}`,
                                            value: JSON.stringify(p)
                                        }))}
                                        onChange={addProductChange}
                                        onSearch={onSearchChange}
                                        label="Prodcut Name"
                                    />
                                </div>
                                <div className='col-md-2 col-6'>
                                    <div className='form-group'>
                                        <label>Quantity:</label>
                                        <input type="text" className='form-control' name='fleet_licence_number' value={addProductForm.quantity} onChange={changeNewProductQty}/>
                                    </div>
                                </div>
                                <div className='col-md-2 col-6'>
                                    <button className='btn btn-primary mt-40 text-white btn-width' onClick={addNewProductClicked}>Submit</button>
                                </div>
                            </div> : null
                        }
                    </div>
                    <div className='gray-bg gray-bar'>
                        <div className='row mx-0'>
                            <div className='col-md-4'>
                                <div className="heading-bar">Product Name</div>
                            </div>
                            <div className='col-md-1'>
                                <div className="heading-bar">Rack</div>
                            </div>
                            <div className='col-md-1'>
                                <div className="heading-bar">QTY</div>
                            </div>
                            <div className='col-md-1'>
                                <div className="heading-bar">Sale Price</div>
                            </div>
							<div className='col-md-2'>
                                <div className="heading-bar">Packing Status</div>
                            </div>							
							<div className='col-md-1'>
                                <div className="heading-bar">outstock qty</div>
                            </div>
                        </div>
                    </div>
                    {
                        cartProducts.map((item) => {
                            return (
                                <div className='white-bar' key={item.id}>
                                    <div className='row mx-0'>
                                        <div className='col-md-4'>
                                            <div className="border-bottom line-break lh-34 pb-1">{`${item.product_title} ${item.variant_value} ${item.variant_name} x ${item.variant_qty} (${item.product_brand})`}</div>
                                        </div>
                                        <div className='col-md-1'>
                                            <div className="heading-bar lh-34">{item.product_rackid}</div>
                                        </div>
                                        <div className='col-md-1'>
                                            <input type="text" className='heading-bar lh-34 form-control' name='fleet_licence_number' value={item.quantity} onChange={(e) => changeExistProductQty(e, item.id)}/>
                                        </div>
                                        <div className='col-md-1'>
                                            <div className="heading-bar lh-34">{`$${item.variant_discounted_price ? item.variant_discounted_price : item.variant_price}`}</div>
                                        </div>
										<div className='col-md-2'>
                                            <div className={`${item.packing_status==2 || item.packing_status==5  ? 'danger' : '' }`}>
											<Select
												showSearch={true}
												className='select col-md-12'
												value={item.packing_status}
												options={packingStatus}
												onChange={(e) => changeExistProductPackingStatus(e, item.id)}
											/>
											</div>
                                        </div>
										<div className='col-md-1'>
                                            <div className="heading-bar lh-34"><span className={`${item.packing_status==2 || item.packing_status==5 ? 'danger' : '' }`}>{`${item.out_stock_qty}`}</span></div>
                                        </div>										
										{  item.item_other_data ? 
										<div className='col-md-1'>
										<a onClick={(e) => showRefundDetails(e, item.item_other_data)} className="btn btn-primary text-white btn-width">view Request</a> </div> : null }										
                                        <div className='col-md-1'>
                                                <i className="fa fa-trash red-icon-product" onClick={() => deleteProduct(item.id)}></i>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
					 <div className='white-bar'>
                        <div className='row mx-0'>
                            <div className='col-md-7'>
                            </div>
                            <div className='col-6 col-md-3'>
                                <div className="heading-bar w-full mb-2">Order Summary</div>
                                <div className="semibold w-full mb-2">Subtotal:</div>
                                <div className="semibold w-full mb-2">Total Discount:</div>
                                <div className="semibold w-full mb-2">Delivery Fees:</div>
								<div className="semibold w-full mb-2">Tax(HST):</div>
                                <div className="semibold w-full mb-2">Coupon Price({orderDetail.coupon_data.coupon_code}):</div>
                                <div className="semibold w-full mb-2">Tip Price:</div>
								<div className="semibold w-full mb-2">Reward Points Used:</div>
                                <div className="heading-bar w-full mb-2">Total Price:</div>
                            </div>
                            <div className='col-6 col-md-2'>
                                <div className="heading-bar w-full mb-2 low-visibility">Order Summary</div>
                                <div className="semibold w-full mb-2">{`$${orderDetail.price_total.toFixed(2)}`}</div>
                                <div className="semibold w-full mb-2">{`$${(orderDetail.price_total - orderDetail.sub_total).toFixed(2)}`}</div>
                                <div className="semibold w-full mb-2">{`$${orderDetail.shipping_charges}`}</div>
								 <div className="semibold w-full mb-2">{`$${orderDetail.products_hst_charge}`}</div>
                                <div className="semibold w-full mb-2">{`$${orderDetail.coupon_discount}`}</div>
                                <div className="semibold w-full mb-2">{`$${orderDetail.driver_tip}`}</div>
								<div className="semibold w-full mb-2">{`$${orderDetail.reward_points_used ? orderDetail.reward_points_used.reward_points_usd : 0 }`} ({`${orderDetail.reward_points_used ? orderDetail.reward_points_used.reward_points : 0 }`})</div>
                                <div className="heading-bar w-full mb-2">{`$${orderDetail.total_paid_amount}`}</div>
                            </div>
                        </div>
                    </div>
                    <div className='white-bar'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='card time-slot mb-4'>
                                    <div className='card-body'>
                                        <h4 className='mb-3'>Delivery Slot</h4>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <h5 className='mb-1'>Select Date:</h5>
                                                <DatePicker disabledDate={(current) => {
                                                        let customDate = moment().format("YYYY-MM-DD");
                                                        return current && current < moment(customDate, "YYYY-MM-DD") || current.isAfter(moment().add(4, "days"));
                                                    }}
                                                    onChange={slotDateChanged}
                                                    className='float-right form-control table-search' 
                                                />
                                                {/* <input type="date" min={new Date()} placeholder='Delivery Date/Time' className='float-right form-control table-search'/> */}
                                            </div>
                                            <div className='col-12'>
                                                <h5 className='mb-1'>Select Time:</h5>
                                            {
                                                slotLoading ? 
                                                <p>Loading Slots...</p> :
                                                slot.length > 0 ? 
                                                slot[0].available_slots.length > 0 ? 
                                                slot[0].available_slots.map((s) => {
                                                    return <button className={slotForm.sentObj && slotForm.sentObj.id == s.id ? 'btn btn-rounded active-time btn-outline-primary mr-2 mb-3' : 'btn btn-rounded btn-outline-primary mr-2 mb-3'} disabled={!s.available_slots} onClick={(e) => slotTimeSlected(e, s)}>{`${convertTimeTo12Hours(s.enter_time)} - ${convertTimeTo12Hours(s.exit_time)}`}</button>
                                                }) : <p>No Available Slots</p>
                                                : <p>No Available Slots</p>
                                            }
                                            </div>
                                        </div>
                                    </div>
								<div className='col-md-3'>
									<div className='form-group'>
										<label> <h5 className='mb-1'>Custom Refund Amount </h5></label>
										<input type="text" className='form-control' name='customRefundAmount' value={customRefundAmount} onChange={onChangeField} />
									</div>
								</div>
								<div className="col-md-4">
									<input type="checkbox"  style={{marginRight: "10px"}} checked={sendEditEmail} onChange={checkboxChanged} name="sendEditEmail"/>
									<label htmlFor="notification_flag"><b>Send Email To Customer ?</b></label>
								</div>
                                </div>
                                <button className='btn btn-primary mt-40 float-right text-white btn-width' onClick={updateOrder}>Update Order</button>
									{
									auth.permissions.includes("ChangeStatusOrder") && 
									<a onClick={showModal} className="btn btn-success mt-40 float-left text-white btn-width">Change Status</a>
									}
                            </div>
                        </div>
                    </div>
                </>
            }
            </div>
        </div>
    )
}
export default OrderEdit;