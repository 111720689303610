import { Table } from 'antd';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { Link,useParams } from 'react-router-dom';
import moment from 'moment';
import { itemRender } from '../Components/paginationfunction';
import { CommonContext } from '../context/CommonContext';
import { useOrderCommentsListing } from '../hooks/OrderHooks';
import { fleetService } from '../Services/FleetService';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { PRODUCT_PREFIX, TOAST_MSG_TYPE, getConstantData,CUSTOMER_PREFIX,NOTICE_PREFIX} from '../utils/Constants';
import { productService } from '../Services/ProductService';
const initialState = { comment_text: ""}
const CardDetails = (props) => {
	const navigate = useNavigate()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo, dashboardPrefix } = useAuth()
    let { orderID,productTitle } = useParams()
	const [formState, setFormState] = useState({...initialState})
	const [sendEmail,setSendEmail]= useState(false)

    const onChangeField = (e) => {  
        const name = e.target.name
        const value = e.target.value
        setFormState({...formState, [name]: value})
    }
    const submitHandler = (e) => {
        e.preventDefault()
        if(!formState.message) {
            showToast("Please enter comment", TOAST_MSG_TYPE.ERROR)
            return
        }
		if(!formState.title) {
            showToast("Please enter title", TOAST_MSG_TYPE.ERROR)
            return
        }
        let paramData = {
			title: formState.title,
            message: formState.message,
			product_id: Number(orderID),
        }
		console.log(paramData)
        const apiPromise = fleetService.sendNotificationInCartProducts(axiosPrivate, paramData)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.sendNotificationsInCartProducts) {
                showToast("Notification added Sucessfully!", TOAST_MSG_TYPE.SUCCESS)
				navigate(`/products-in-cart-reports`)
            }
        })
    }
	
	const checkboxChanged = (e) => {
		var newValue = !sendEmail
		setSendEmail(newValue)
    }
    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Send Notification Cart Products</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Send Notification Cart Products</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
								<li className="breadcrumb-item active"><Link to={`/products/edit/${orderID}`}> #{orderID} - {productTitle}</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
							<div className='col-md-12'>
								<label>Notice Title *</label>
								<input type="text" className='form-control' name='title' value={formState.title} onChange={onChangeField} />
							</div>
							<div className='col-md-12'>
								<label>Notice Message *</label>
								<textarea className='form-control' rows={4} value={formState.message} name='message' onChange={onChangeField}></textarea>
                            </div>
							<div className='col-md-12'>
							<button className='btn btn-primary mt-40 float-right text-white btn-width' onClick={submitHandler}>Submit</button>
                            </div>
						</div>
                    </div>
					<div className="card-body">
					<div className='col-md-12'>
						
                    </div>                         
					</div>
				 </div>
            </div>
        </div>
    
    );
}
export default CardDetails