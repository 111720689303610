import { useEffect, useReducer } from "react"
import { storeService } from "../Services/StoreService"
import { ACTIONS } from "../utils/Constants"
import { productService } from "../Services/ProductService"

function CustomersListReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, customerData: action.payload.data, total: action.payload.total}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}
function useCostomerList(axios, params) {
    const [state, dispatch] = useReducer(CustomersListReducer, {loading: false, customerData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        productService.getCustomers(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.customersListing.list, total: res.data.customersListing.total}})
        })
    }, [params])
    return state
}
function useNotificationCostomerList(axios, params) {
    const [state, dispatch] = useReducer(CustomersListReducer, {loading: false, customerData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        productService.getNotificationCustomers(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.notificationCustomersList.list, total: res.data.notificationCustomersList.total}})
        })
    }, [params])
    return state
}
function useCostomerAppTokenList(axios, params) {
    const [state, dispatch] = useReducer(CustomersListReducer, {loading: false, customerData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        productService.pushNotificationCustomers(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.pushNotificationCustomers}})
        })
    }, [params])
    return state
}
function CardITEMListReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state}
        case ACTIONS.GET_DATA:
            return {...state, customerData: action.payload.data}
        case ACTIONS.ERROR:
            return {...state, error: action.error}
        default:
            return state
    }
}
function useCardITEMList(axios, cart_id) {
    const [state, dispatch] = useReducer(CardITEMListReducer, { customerData: [], error: ""})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        productService.getCardItems(axios, cart_id).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.cartItemsListing.cart_items}})
        })
    }, [cart_id])
    return state
}
function merchantProductListReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, productData: action.payload.data, 
			total: action.payload.total, 
			merchants: action.payload.merchants,  
			stores: action.payload.stores,
			brands: action.payload.brands,
			suppliers: action.payload.suppliers
			}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}
function useMerchantProductList(axios, params) {
    const [state, dispatch] = useReducer(merchantProductListReducer, {loading: false, productData: [], error: "", total: 0,merchants:[],stores:[]})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        productService.productVariantListingAdmin(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {
		    data: res.data.productVariantListingAdmin.list, 
			total: res.data.productVariantListingAdmin.total,
			merchants: res.data.productVariantListingAdmin.merchants,
			stores: res.data.productVariantListingAdmin.stores,
			brands: res.data.productVariantListingAdmin.product_brands,
			suppliers: res.data.productVariantListingAdmin.product_suppliers
			}})
        })
    }, [params])
    return state
}

function searchProductListReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, productData: action.payload.data}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}
function useSearchProductList(axios, params) {
    const [state, dispatch] = useReducer(searchProductListReducer, {loading: false, productData: [], error: ""})
    useEffect(() => {
        if(params.search.length >= 3) {
            console.log("Params", "Inside IF")
            dispatch({type: ACTIONS.MAKE_REQUEST})
            productService.searchProduct(axios, params).then(res => {
                dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.searchProductsByOrderID.list}})
            })
        }else {
            console.log("Params", "Inside Else", params)
            dispatch({type: ACTIONS.GET_DATA, payload: {data: []}})
        }
    }, [params])
    return state
}

function slotListReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true, slot: []}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, slot: action.payload.data}
        case ACTIONS.ERROR:
            return {...state, loading: false, slot: [],error: action.error}
        default:
            return state
    }
}
function useSlotList(axios, city, date) {
    const [state, dispatch] = useReducer(slotListReducer, {loading: false, slot: [], error: ""})
    useEffect(() => {
        if(date && city) {
			console.log(date,city)
            dispatch({type: ACTIONS.MAKE_REQUEST})
            productService.getOrderSlots(axios, date, city).then(res => {
                dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.shippingSlotsByCity}})
            })
        }else {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: []}})
        }
    }, [date, city])
    return state
}

function categoryMenusListReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true, menuData: []}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, menuData: action.payload.menuData}
        case ACTIONS.ERROR:
            return {...state, loading: false, menuData: [],error: action.error}
        default:
            return state
    }
}
function useMenusList(axios, params) {
    const [state, dispatch] = useReducer(categoryMenusListReducer, {loading: false, menuData: [], error: ""})
    useEffect(() => {
        if(params && params.store) {
            dispatch({type: ACTIONS.MAKE_REQUEST})
            productService.productCategoryList(axios,params.store).then(res => {
                dispatch({type: ACTIONS.GET_DATA, payload: {menuData: res.data.multiStoreMenuListing.list}})
            })
        }else {
            dispatch({type: ACTIONS.GET_DATA, payload: {menuData: []}})
        }
    }, [params])
    return state
}
export {
	useMenusList,
    useMerchantProductList,
    useSearchProductList,
    useSlotList,
	useCostomerAppTokenList,
	useCostomerList,
	useNotificationCostomerList,
	useCardITEMList
}