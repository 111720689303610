import { useEffect, useReducer } from "react"
import { categoryService } from "../Services/CategoryService"
import { storeService } from "../Services/StoreService"
import { ACTIONS } from "../utils/Constants"

function categoryListReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, categoryData: action.payload.data, total: action.payload.total}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}

function parentCategoryReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, categoryData: action.payload.data, total: action.payload.data.length}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}

function useAdminCateogryList(axios, params) {
    const [state, dispatch] = useReducer(categoryListReducer, {loading: true, categoryData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        categoryService.getAllAdminCateogry(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.mainCategoriesAdmin.list, total: res.data.mainCategoriesAdmin.total}})
        })
    }, [params])
    return state
}
function useCustomerAllOrderIds(axios, params) {
    const [state, dispatch] = useReducer(categoryListReducer, {loading: true, categoryData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        categoryService.getCustomerOrderIds(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.customerAllOrderIds.list, total: 0}})
        })
    }, [params])
    return state
}

function useMerchantCateogryList(axios, params) {
    const [state, dispatch] = useReducer(categoryListReducer, {loading: true, categoryData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        categoryService.getAllMerchantCateogry(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.categoriesMerchant.list, total: res.data.categoriesMerchant.total}})
        })
    }, [params])
    return state
}

function useMerchantParentCateogry(axios, catID) {
    const [state, dispatch] = useReducer(parentCategoryReducer, {loading: true, categoryData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        categoryService.getMerchantParentCategories(axios, catID).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.parentCategoriesMerchant}})
        })
    }, [catID])
    return state
}

export {
    useAdminCateogryList,
    useMerchantCateogryList,
    useMerchantParentCateogry,
	useCustomerAllOrderIds
}