import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import { useNavigate } from 'react-router-dom';
import { FLEET_PREFIX, TOAST_MSG_TYPE,ORDER_STATUS_IMG } from '../../utils/Constants';
import ImageUpload from "../ImageUpload"
import { DatePicker,Select } from 'antd';
import moment from 'moment';
import { fleetService } from '../../Services/FleetService';
import { productService } from '../../Services/ProductService';
import { useCustomerAllOrderIds } from '../../hooks/CategoryHooks';
import { CKEditor } from 'ckeditor4-react';

const COUPON_TYPE = [
    {label: "Order Issue", value: "Order Issue"},
    {label: "General Issue", value: "General Issue"}
]

const initialState = { ticket_type:"General Issue",customer_id:null,ticket_subject:"",comment_text: "", comment_image1: {server: ""},comment_image2: {server: ""},comment_image3: {server: ""},comment_image4: {server: ""},}

const FleetForm = (props) => {
    const navigate = useNavigate()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [formState, setFormState] = useState({...initialState})
	console.log(formState)		
    const [disabled, setDisabled] = useState(false)
    const [showLoader, setShowLoader] = useState(true)
	const [OrderList, setOrderList] = useState(false)
	const [customerData, setCustomerData] = useState([]);
	const [params, setParams] = useState({customer_id: 0 })
	const {categoryData} = useCustomerAllOrderIds(axiosPrivate, params)
	
	console.log(categoryData,"categoryData")

    useEffect(() => {
        if(props.fleet_id) {
            fleetService.getFleetByID(axiosPrivate, props.fleet_id).then(res => {
                if(res.data.fleetDetailsById) {
                    const data = res.data.fleetDetailsById.fleet_details
                    setFormState({
                        ...data,
                        fleet_year: data.fleet_year ? moment(data.fleet_year) : "",
                        fleetYear: data.fleet_year,
                        insurance_expiry: data.insurance_expiry ? moment(data.insurance_expiry) : "",
                        insuranceExpiry: data.insurance_expiry
                    })
                    setShowLoader(false)
                }else {
                    navigate(`${FLEET_PREFIX}`)
                }
            })    
        }else {
            setShowLoader(false)
        }
    }, [props.fleet_id])

    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])

    const onChangeField = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFormState({...formState, [name]: value})
    }
	const handleInputChangeCustomers = (inputValue) => {
		let paramData= { search_string:inputValue, customer_city:""  }
			if (inputValue && inputValue.length>2 && formState.firebase_topic_name!=="ORDER_CUSTOMERS") {
			productService.pushNotificationCustomers(axiosPrivate, paramData )
			  .then((res) => {
				const relatedProductList = res.data.pushNotificationCustomers;
				setCustomerData(relatedProductList);
				return;
			  })
			  .catch(error => {
				console.error('Error searching for related products:', error);
			  });
			}
	}
	const onChangeProduct = (e) => {
	    setFormState({...formState, customer_id: e})
		setParams({customer_id: e})
    }
	const onChangeCategory = (e) => {
		setFormState({...formState, ticket_subject: e})	

    }
    const onChangeBannerType = (value) => {
		setFormState({...formState, ticket_type: value});
    }
    const onChangeModalYear = (value, dateString) => {
        setFormState({...formState, fleet_year: moment(dateString), fleetYear: dateString})
    }
    const onChangeInsuranceExpiry = (value, dateString) => {
        setFormState({...formState, insurance_expiry: moment(dateString), insuranceExpiry: dateString})
    }
    const submitHandler = (e) => {
        e.preventDefault()
        if(!formState.ticket_type) {
            showToast("Please select Ticket Type", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.customer_id) {
            showToast("Please customer id", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.ticket_subject) {
            showToast("Please Fill subject", TOAST_MSG_TYPE.ERROR)
            return
        }
        let param = {
            ticket_type: formState.ticket_type,
            customer_id: formState.customer_id,
            ticket_subject: formState.ticket_subject,
            ticket_message: formState.comment_text,
			send_notification: true,
			email_notification: true,
        }
		console.log(param)
	    if("local" in formState.comment_image1) {
            delete formState.comment_image1.local
        }
		if("local" in formState.comment_image2) {
            delete formState.comment_image2.local
        }
		if("local" in formState.comment_image3) {
            delete formState.comment_image3.local
        }
		if("local" in formState.comment_image4) {
            delete formState.comment_image4.local
        }
        const apiPromise = fleetService.addUpdateSupportComment(axiosPrivate, param,formState.comment_image1.server, formState.comment_image2.server,formState.comment_image3.server,formState.comment_image4.server)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
               showToast(props.crud === "create" ? "Comment Submitted Sucessfully!" : "Comment Submitted Sucessfully", TOAST_MSG_TYPE.SUCCESS)
				navigate(`/support-tickets`)
        })
    }

    return (
        <>
        {
            showLoader ? <p>Loading...</p> :
            <form>
                <div className='row mb-4'>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Ticket/Issue Type*</label>
                            <Select 
                                showSearch={true}
                                className='select col-md-12'
                                value={formState.ticket_type}
                                options={COUPON_TYPE}
                                onChange={onChangeBannerType}
                            />
                        </div>
                    </div>
					<div className='col-md-3'>
						<div className='form-group'>
							<label>Select Customer</label>
							<Select 
								showSearch={true}
								className='select col-md-12'
								value={formState.customer_id}
								options={customerData}
								onChange={onChangeProduct}
								optionFilterProp={"email"}
								fieldNames={{label: "email", value: "id"}}
								onSearch={handleInputChangeCustomers}
							/>
						</div>
					</div>
					{
                    formState.ticket_type=="General Issue" ?  
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Ticket Subject</label>
                            <input type="text" className='form-control' name='ticket_subject' value={formState.ticket_subject} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>: <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Order Id</label>
							<Select 
								showSearch={true}
								className='select col-md-12'
								value={formState.ticket_subject}
								options={categoryData}
								onChange={onChangeCategory}
								optionFilterProp={"order_id"}
								fieldNames={{label: "order_id", value: "order_id"}}
							/>
                        </div>
                    </div> 
					}
					<div className='row'>
                    <div className="col-sm-3 col-3">
							<label>Upload Image:</label>
							<ImageUpload
							fileName={formState.comment_image1.server}
							fileList={
							formState.comment_image1.server
								? [
									{
									uid: formState.comment_image1,
									url:"local" in formState.comment_image1 ? `${formState.comment_image1.local.url}` : `${ORDER_STATUS_IMG}/${formState.comment_image1.server}`
									}
								]
								: []
							}
							getFile={file => {
								setFormState({...formState, comment_image1: file});
							}}
							onRemove={() => setFormState({...formState, comment_image1: {server: ""}})}
							crud="create"
						/>
						</div>
						<div className="col-sm-3 col-3">
							<label>Upload Image:</label>
							<ImageUpload
							fileName={formState.comment_image2.server}
							fileList={
							formState.comment_image2.server
								? [
									{
									uid: formState.comment_image2,
									url:"local" in formState.comment_image2 ? `${formState.comment_image2.local.url}` : `${ORDER_STATUS_IMG}/${formState.comment_image2.server}`
									}
								]
								: []
							}
							getFile={file => {
								setFormState({...formState, comment_image2: file});
							}}
							onRemove={() => setFormState({...formState, comment_image2: {server: ""}})}
							crud="create"
						/>
						</div>
						<div className="col-sm-3 col-3">
							<label>Upload Image:</label>
							<ImageUpload
							fileName={formState.comment_image3.server}
							fileList={
							formState.comment_image3.server
								? [
									{
									uid: formState.comment_image3,
									url:"local" in formState.comment_image3 ? `${formState.comment_image3.local.url}` : `${ORDER_STATUS_IMG}/${formState.comment_image3.server}`
									}
								]
								: []
							}
							getFile={file => {
								setFormState({...formState, comment_image3: file});
							}}
							onRemove={() => setFormState({...formState, comment_image3: {server: ""}})}
							crud="create"
						/>
						</div>
						<div className="col-sm-3 col-3">
							<label>Upload Image:</label>
							<ImageUpload
							fileName={formState.comment_image4.server}
							fileList={
							formState.comment_image4.server
								? [
									{
									uid: formState.comment_image4,
									url:"local" in formState.comment_image4 ? `${formState.comment_image4.local.url}` : `${ORDER_STATUS_IMG}/${formState.comment_image4.server}`
									}
								]
								: []
							}
							getFile={file => {
								setFormState({...formState, comment_image4: file});
							}}
							onRemove={() => setFormState({...formState, comment_image4: {server: ""}})}
							crud="create"
						/>
						</div>
					</div>
                    <div className='col-md-12'>
					<label>Note:</label>
					    <textarea 
							className='form-control'
							rows={6} 
							value={formState.comment_text} 
							name='comment_text' 
							onChange={onChangeField}>
						</textarea>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className="btn btn-primary btn-width mx-auto d-block" onClick={submitHandler}>Save</button>
                    </div>
                </div>
            </form>
        }
        </>
    )
}

export default FleetForm