import { Table, Select } from 'antd';
import React, { useContext, useMemo, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { itemRender } from '../Components/paginationfunction';
import { CommonContext } from '../context/CommonContext';
import { useMerchantProductList,useMenusList } from '../hooks/ProductHooks';
import ReactSelect from 'react-select';
import useAuth from '../hooks/useAuth';
import { PRODUCT_PREFIX, TOAST_MSG_TYPE, getConstantData,API_BASE_URL} from '../utils/Constants';
import { productService } from '../Services/ProductService';

const CARD_TYPE = [
    {label: "All Products", value: ""},
	{label: "Out Of Stock All Products", value: "OutOfStock"},
	{label: "OutOfStock Active Products", value: "Out_Of_Stock_Only_Active"},
    {label: "In Stock", value: "InStock"},
	{label: "Top Selling Products", value: "TopSelling"},
	{label: "Free Products", value: "FreeProducts"},
	{label: "Top Deal MissingAny Popup", value: "Discounted_MissingAny_Popup"},
	{label: "Top Selling MissingAny Popup", value: "Top_Selling_MissingAny_Popup"}
]
const ProductsList = () => {
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo, dashboardPrefix } = useAuth()
	const[Options,setOptions] = useState([]);
	const [menuparams, setMenuParams] = useState({store: [] })
	const {menuData, loading2} = useMenusList(axiosPrivate, menuparams)
	console.log("menuData",Options)
    const [params, setParams] = useState({paginated: true,product_category:0, limit: 10, offset: 0, search: "", field: "id", sort: "ASC", counter: 0,stockFilter:"",merchant_id: null,store_id: null,product_brand:"",product_supplier:null})
    const {productData, loading, error, total,merchants,stores,brands,suppliers} = useMerchantProductList(axiosPrivate, params)
	
    const changeProductStatus = (e, productRecord, newStatus) => {
        e.preventDefault()
        let apiParams = {
            id: productRecord.variant_id,
            status: newStatus
        }
        const apiPromise = productService.changeProductVariantStatus(axiosPrivate, apiParams)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.changeProductVariantStatus) {
                showToast("Product Status Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
            }else {
                showToast("Something Wrong. Please try again later", TOAST_MSG_TYPE.ERROR)
            }
            const newCounter = params.counter + 1
            setParams((prev) => {
                return {
                    ...prev,
                    counter: newCounter
                }
            })
        })
    }
	useEffect(()=>{
        const categoryList = menuData;
        const options = categoryList?.map(category => ({
            label: category.main_cat_title,
            options: category.subcats.map(subcat => ({
              value: parseInt(subcat.menu_item_id), 
              label: subcat.child_cat_title
            }))
        }));
        setOptions(options);
    },[menuData])

    const render = useMemo(() => {
        const columns = [
		    {
                title: 'Product Id',
                dataIndex: 'id',
                sorter: true,
            },
			{
                title: 'sku',
                dataIndex: 'product_sku',
                sorter: true,
            },
			{
                title: 'Store',
                dataIndex: 'store_name',
                sorter: true,
            },
            {
                title: 'Product Title',
                dataIndex: 'product_title',
                sorter: true,
            },
			{
                title: 'Brand',
                dataIndex: 'product_brand',
                sorter: true,
            },
            {
                title: 'Price',
				render: (text, record) => {
                    return record.variant_discounted_price && record.variant_discounted_price >0 ? `${record.variant_discounted_price}` : `${record.variant_price}`
                }
            },
            {
                title: 'Variant',
                dataIndex: 'variant_value'
            },
            {
                title: 'Qty',
                dataIndex: 'variant_qty'
            },
			{
                title: 'Stock',
                render: (text, record) => {
                    return record.variant_stock<1 && record.variant_outstock_date ? `${record.variant_outstock_date} (${record.variant_stock})`: record.variant_stock
				}
            },
			{
                title: 'Supplier Price',
                dataIndex: 'varaint_supplier_price'
            },
			{
				title: 'Duplicate',
				render: (text, record) => {
				return (<>
				<a className="btn btn-primary" href="#" onClick={(e) => duplicateANProduct(record.id)}>Duplicate</a>
				</>)
				}
            },
            {
                title: 'Status',
                render: (text, record) => (
                    <div className="dropdown action-label text-center">
                        <a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">{`${getProductStatusString(record.variant_status)}`}</a>
                        <div className="dropdown-menu dropdown-menu-right">
                        {
                            getConstantData("STATUS", auth.constants).map(status => {
                                return <a key={`${record.id}_${status.id}`} className="dropdown-item" href="#" onClick={(e) => changeProductStatus(e, record, status.id)}>{status.value}</a>
                            })
                        }
                        </div>
                    </div>
                )    
            },
            {
                title: 'Action',
                render: (text, record) => (
                    <div className="dropdown dropdown-action text-right">
                        <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                        <div className="dropdown-menu dropdown-menu-right">
                            <Link className="dropdown-item" to={`${PRODUCT_PREFIX}/edit/${record.id}`} data-toggle="modal" data-target="#edit_leave"><i className="fa fa-pencil m-r-5" /> Edit</Link>
                        </div>
                    </div>
                ),
            }
        ]
        return columns
    }, [auth.permissions])

    const getProductStatusString = (status) => {
        var statusObj = {}
        if(auth.constants.hasOwnProperty("STATUS")) {
            statusObj = auth.constants["STATUS"].find((s) => s.id == status)
        }
        return statusObj.hasOwnProperty("value") ? statusObj.value : ""
    }

    const searchChanged = (e) => {
        const value = e.target.value
        setParams({...params, offset: 0, search: value})
    }

    const handleTableChange = (pagination, filters, sorter, extra) => {
        
        if("order" in sorter) {
            let offset = pagination.pageSize * (pagination.current - 1)
            setParams({...params, offset: offset, field: sorter.field, sort: sorter.order === "ascend" ? "ASC" : "DESC"})
            return
        }
        let offset = pagination.pageSize * (pagination.current - 1)
        setParams({...params, offset: offset})
    }
	const onChangeStockFilter = (value) => {
		setParams({...params, stockFilter: value})
	}	
	const onChangeStores = (value) => {
		setParams({...params, store_id: parseInt(value)})
		setMenuParams({...menuparams, store_id: parseInt(value)})
	}
	const onChangeMenu = (val) => {
		console.log("category",val.value)
		setParams({...params, product_category: parseInt(val.value)})
	}
	const onChangeProductBrand = (value) => {
		setParams({...params, product_brand: value})
	}
	const onChangeProductSupplier = (value) => {
		setParams({...params, product_supplier: value})
	}
	const onChangeMerchant= (value) => {
		setParams({...params, merchant_id: parseInt(value)})
	}	
	const productXL = (e) => {
        e.preventDefault()
		let params={}
        const apiPromise = productService.getALLProductsExcel(axiosPrivate,params)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            const data = res.data.excelProductsList
            if(data.status == "success") {
                const filePath = data.path.replace("./", "")
                const link = document.createElement("a");
                link.download = `all-Products-list.xlsx`;
                link.href = `${API_BASE_URL}${filePath}`;
                link.click();
            }
        })
    }
	const duplicateANProduct = (product_id) => {
		let params={}
		console.log(product_id,"product_id")
        const apiPromise = productService.duplicateProduct(axiosPrivate,product_id)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            const data = res.data.duplicateAnProduct
			console.log(data)
            if(data.product_id) {
                const link = document.createElement("a");
                link.href = `${PRODUCT_PREFIX}/edit/${data.product_id}`;
                link.click();
            }
        })
    }
    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Products</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Manage Product</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active">Manage Product</li>
                            </ul>
                        </div>
                        <div className="col-sm-6">
                            <Link to={`${PRODUCT_PREFIX}/create`} className="btn btn-primary btn-width float-right d-block">Create Product</Link>
                        </div>
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
                            <div className='col-md-3'>
							<label>Search</label>
                                <input type="text" placeholder='Search' className='form-control col-md-12' onChange={(e) => searchChanged(e)}/>
                            </div>
							<div className='col-md-3'>
								<div className='form-group'>
									<label>Filters</label>
									<Select 
										className='select col-md-12'
										options={CARD_TYPE}
										value={params.stockFilter}
										onChange={onChangeStockFilter}
									/>
								</div>
							</div>
							<div className='col-md-3'>
							<div className='form-group'>
									<label>Select Merchant</label>
									<Select 
										className='select col-md-12'
										value={params.merchant_id}
										options={merchants}
										onChange={onChangeMerchant}
										fieldNames={{label: "business_name", value: "merchant_id"}}
									/>
								</div>
							</div>
							<div className='col-md-3'>
							<div className='form-group'>
									<label>Select Store</label>
									<Select 
										className='select col-md-12'
										value={params.store_id}
										options={stores}
										onChange={onChangeStores}
										fieldNames={{label: "store_name", value: "store_id"}}
									/>
								</div>
							</div>
							<div className='col-md-3'>
							<div className='form-group'>
									<label>Select Brand</label>
									<Select 
									showSearch={true}
										className='select col-md-12'
										value={params.product_brand}
										options={brands}
										onChange={onChangeProductBrand}
										fieldNames={{label: "product_brand", value: "product_brand"}}
									/>
								</div>
							</div>
							<div className='col-md-3'>
							<div className='form-group'>
									<label>Select Supplier</label>
									<Select 
									showSearch={true}
										className='select col-md-12'
										value={params.product_supplier}
										options={suppliers}
										onChange={onChangeProductSupplier}
										fieldNames={{label: "product_supplier", value: "product_supplier"}}
									/>
								</div>
							</div>
							<div className='col-md-3'>
							<div className='form-group'>
									<label>Select Category/Menu</label>
									<ReactSelect
										options={Options}
										onChange={onChangeMenu}
										isLoading={false}
										isDisabled={false}
										loadingMessage={"Loading Category"}
									/>
							</div>
							</div>
							<div className="col-md-3">                                    
							    <a className="btn btn-success" href="#" onClick={(e) => productXL(e)}>Download All Products</a> 
							</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
                                pagination= { {total : total,pageSize: 10, 
                                    showSizeChanger : false,itemRender : itemRender } }
                                style = {{overflowX : 'auto'}}
                                columns={render}
                                loading={loading}               
                                bordered
                                dataSource={productData}
                                rowKey={record => record.variant_id}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}
export default ProductsList